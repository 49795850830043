import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { signout } from "@/redux/actions/session-action-types";
const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "DF-Version": process.env.REACT_APP_DF_VERSIONS,
  },
  credentials: "include",
});

const baseQueryWithAuthCheck = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // force the user to sign out
    api.dispatch(signout());
  }
  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithAuthCheck,
  tagTypes: ["Project", "Study", "Benchmarks", "Contexts", "ContextPacks", "Report"],
  endpoints: () => ({}),
});
