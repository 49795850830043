import { call, put, delay } from "redux-saga/effects";
import { Toaster } from "../components/Toast";
import { signout } from "../redux/sagas/session";
import axiosInstance from "../utilities/axiosInstanceProjects";
import { showLoader, hideLoader } from "../redux/actions/file-action-types";
export function* client(payload) {
  const data = { ...payload };
  try {
    const { data: result, error } = yield call(axiosInstance, data);
    return {
      error,
      result,
    };
  } catch (error) {
    return {
      error,
      result: null,
    };
  }
}

function* ToastMyError(error) {
  let APIErrorMessage = error?.response?.data?.error?.details[0]?.message;
  if (APIErrorMessage) {
    yield call(Toaster, { message: `${APIErrorMessage}`, type: "error" });
  }
}

function* handleError(error) {
  if (error?.response?.status === 401) {
    yield call(signout);
  } else {
    yield call(ToastMyError, error);
  }
}

function* httpClient(payload, isLoader = true, authorization = true) {
  if (!navigator.onLine) {
    return {
      error: true,
      result: null,
    };
  }
  if (isLoader) {
    yield put(showLoader());
    yield delay(250);
  }
  const { result, error } = yield call(
    client,
    payload,
    isLoader,
    authorization
  );
  if (error) {
    yield call(handleError, error);
  }
  yield put(hideLoader());
  return { error, result };
}

export default httpClient;
