import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";

import { Contexts } from "@/features/library/components/Contexts";
import { Benchmarks } from "@/features/library/components/Benchmarks";
import { Benchmark } from "@/features/library/components/Benchmark";
import { ContextPacks } from "@/features/library/components/ContextPacks";
/* Layouts */
import NavLayout from "../layouts/NavLayout";
import AuthLayout from "../layouts/AuthLayout";
/* Route Components */
const AuthIFrame = lazy(() => import("../containers/AuthIFrame"));
const Projects = lazy(() => import("../features/projects"));
const Study = lazy(() => import("../features/study"));
const Studies = lazy(() => import("../features/studies"));
const NotFound = lazy(() => import("../containers/NotFound"));
const Library = lazy(() => import("@/features/library"));

function Navigation() {
  const role = useSelector((state) => state.session?.role);
  const accountId = useSelector((state) => state.session?.account?.id);

  const mapRoleToLandingPage = (role) => {
    const PublicLandingPage = "/";
    const UserLandingPage = "/projects";

    switch (role) {
      case "super":
      case "admin":
      case "user":
        return UserLandingPage;

      case null:
        return PublicLandingPage;

      default:
        break;
    }
  };
  return (
    <Suspense fallback={<div className="bg-light"></div>}>
      <Routes>
        <Route
          path="/"
          element={role !== null && accountId !== undefined ? <Navigate to="/projects" /> : <Navigate to="/signin" />}
        />
        <Route element={<AuthLayout mapRoleToLandingPage={mapRoleToLandingPage} role={role} accountId={accountId}/>}>
          <Route path="/signin" element={<AuthIFrame path="/signin" /> } />
          <Route path="/signout" element={ <AuthIFrame path="/signout" /> } />
        </Route>

        <Route path="/projects/:project_id/studies/:study_id" element={<Study />} />

        {/* All routes that need to sit within NavLayout */}
        <Route element={<NavLayout />}>
          <Route path="/projects">
            <Route index element={<Projects />} />
            <Route path=":project_id/studies" element={<Studies />} />
          </Route>
          <Route element={<Library />} path="/library">
            <Route index path="contexts" element={<Contexts />} />
            <Route path="benchmarks" element={<Benchmarks />} />
            <Route path="benchmarks/:benchmark_id" element={<Benchmark />} />
            <Route path="context-packs" element={<ContextPacks />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default Navigation;
